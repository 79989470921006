import React, {forwardRef, useCallback, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import fp from 'lodash/fp';
import {useSnackbar} from 'notistack';

import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Chip,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@material-ui/core';
import * as I from '@material-ui/icons';
import platform from 'platform';

import {useApi, ducks, lib} from '@arborian/narrf';

import {url_for} from 'ccm/routes';

import PasswordAddForm from 'ccm/components/forms/PasswordAddForm';
import PasswordChangeForm from 'ccm/components/forms/PasswordChangeForm';
import {createSelector} from '@reduxjs/toolkit';
import {useCurrentUser} from 'ccm/dataSource/misc';
import {useAllAuthProviders} from 'ccm/dataSource/allResources';

import {useDialog} from 'ccm/components/dialogs';
import PromptDialog from 'ccm/components/dialogs/PromptDialog';

function IdentityChip({identity, onDelete}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(identity));
    const image = fp.get('plugin.attributes.image_url', rel);
    const avatar = <Avatar src={image} icon={<I.Face />} />;
    const claims = identity.attributes.claims;
    const claim = claims.name || claims.email || claims.username;

    const handleDelete = (ev) => onDelete(identity);

    return (
        <Chip
            label={claim}
            onDelete={onDelete && handleDelete}
            avatar={avatar}
        />
    );
}

const ProviderMenuItem = forwardRef(({value}, ref) => {
    const api = useApi();
    const plugin_id = fp.get('attributes.plugin_id', value);
    const plugin = useSelector(
        ducks.jsonapi.selectObject(['ProviderPlugin', plugin_id])
    );
    const image = fp.get('attributes.image_url', plugin);
    const avatar = <Avatar src={image} icon={<I.Face />} />;
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri,
        provider_id: value.id,
        intent: 'link',
        state: {next: url_for('profile')},
    });
    const handleClick = (ev) => {
        console.log('visit', authorizeLink);
        window.location = authorizeLink;
    };
    if (!fp.get('attributes.allow_link', plugin)) return null;
    return (
        <MenuItem ref={ref} onClick={handleClick}>
            <ListItemIcon>{avatar}</ListItemIcon>
            Link new {fp.get('attributes.name', plugin)} account
        </MenuItem>
    );
});

function WebAuthNMenuItem({value, onComplete}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const promptDialog = useDialog(PromptDialog);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const reg_url = api.url_for('webauthn.create_registration', {
        provider_id: value.id,
    });
    const handleClick = async (ev) => {
        try {
            const credentialData = await lib.webauthn.register(
                api,
                value,
                userinfo
            );
            const credentialName = await promptDialog({
                title: 'Device Name',
                prompt: 'Please set a name for this device/credentials.',
                submitLabel: 'Save Credentials',
                defaultValue: `${platform.name} on ${platform.os.family}`,
            });
            if (!credentialName) {
                return;
            }
            const identity = await api.fetchJson(reg_url, {
                method: 'POST',
                json: {
                    data: {
                        type: 'WebAuthNRegistration',
                        attributes: {
                            name: credentialName,
                            credentialData,
                        },
                    },
                },
            });

            const clientId = api.client_id;
            const credentialId = fp.get(
                'data.attributes.claims.credential_id',
                identity
            );
            localStorage.setItem(`${clientId}.webauthn`, credentialId);
        } catch (e) {
            console.error('Unable to create webauthn identity', e);
            enqueueSnackbar(`Unable to create identity: ${e}`, {
                persist: false,
                variant: 'error',
            });
        } finally {
            onComplete();
        }
    };
    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <I.Fingerprint />
            </ListItemIcon>
            Add a FaceID / Fingerprint
        </MenuItem>
    );
}

function NewIdentity() {
    const [anchorEl, setAnchorEl] = useState();
    const providers = fp.values(useAllAuthProviders());
    // const providers = useSelector(selectProviders);
    const handleClose = () => setAnchorEl(null);
    const handleClick = (ev) => {
        setAnchorEl(ev.target);
    };
    const nonWebAuthNProviders = fp.filter(
        (p) => p.attributes.plugin_id !== 'webauthn',
        providers
    );
    const webAuthNProviders = fp.filter(
        (p) => p.attributes.plugin_id === 'webauthn',
        providers
    );
    return (
        <>
            <Button variant="outlined" onClick={handleClick}>
                <I.Add /> Add
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {fp.map(
                    (p) => (
                        <WebAuthNMenuItem
                            key={p.id}
                            value={p}
                            onComplete={handleClose}
                        />
                    ),
                    webAuthNProviders
                )}
                {fp.map(
                    (p) => (
                        <ProviderMenuItem key={p.id} value={p} />
                    ),
                    nonWebAuthNProviders
                )}
            </Menu>
        </>
    );
}

const selectSubIdentities = (subId) =>
    createSelector(
        ducks.jsonapi.selectObject('Identity'),
        fp.filter((ident) => {
            const match = fp.find((s) => s === subId, ident.attributes.sub_ids);
            return !!match;
        })
    );

export default function CredentialsCard() {
    const api = useApi();
    const dispatch = useDispatch();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const sub = useSelector(ducks.jsonapi.selectObject(['Sub', userinfo.sub]));
    const idents = useSelector(selectSubIdentities(userinfo.sub));
    const curUser = useCurrentUser();
    const {enqueueSnackbar} = useSnackbar();
    console.log({api});

    useEffect(() => {
        if (!sub) {
            api.fetchJsonApi(api.url_for('auth.sub', {sub_id: userinfo.sub}), {
                include: [
                    'identities',
                    'identities.provider',
                    'identities.plugin',
                ],
            });
        } else {
            api.fetchJsonApi(api.url_for('user.UserCollection'), {
                filter: {sub: sub.id},
            });
        }
    }, [api, sub, userinfo]);

    const handleAddPassword = useCallback(
        async (attributes, {resetForm}) => {
            console.log('Try to add password', {
                attributes,
                resetForm,
                curUser,
            });
            await api.fetch(
                api.url_for('user.Password', {user_id: curUser.id}),
                {
                    method: 'PUT',
                    json: {data: {type: 'Password', attributes}},
                }
            );
            api.fetchAllJsonApi(api.url_for('auth.subs'), {
                include: ['identities'],
            });
            enqueueSnackbar('Password set', {
                persist: false,
                variant: 'success',
            });
            resetForm();
        },
        [api, curUser, enqueueSnackbar]
    );

    const onDelete = (ident) => {
        api.fetch(ident.links.self, {method: 'DELETE'});
        if (fp.isEqual(ident.attributes.plugin_id, 'webauthn')) {
            // Delete webauthn token from local storage
            localStorage.removeItem(`${api.client_id}.webauthn`);
        }
        dispatch(ducks.jsonapi.deleteData(ident));
    };
    const canDelete = fp.size(idents) > 1;
    const passwordIdent = fp.find(
        (ident) => ident.attributes.plugin_id === 'password',
        idents
    );

    return (
        <Card>
            <CardHeader title="My Login Credentials" />
            <CardContent>
                {fp.map(
                    (identity) => (
                        <IdentityChip
                            key={identity.id}
                            identity={identity}
                            onDelete={canDelete ? onDelete : null}
                        />
                    ),
                    idents
                )}
                <NewIdentity />
                {passwordIdent ? (
                    <PasswordChangeForm onSubmit={handleAddPassword} />
                ) : (
                    <PasswordAddForm onSubmit={handleAddPassword} />
                )}
            </CardContent>
        </Card>
    );
}
